import { DateTime } from 'luxon';

function uuid4() { // Public Domain/MIT
  let d = new Date().getTime();// Timestamp
  // Time in microseconds since page-load or 0 if unsupported
  let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16;// random number between 0 and 16
    if (d > 0) { // Use timestamp until depleted
      // eslint-disable-next-line no-bitwise
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else { // Use microseconds since page-load if supported
      // eslint-disable-next-line no-bitwise
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    // eslint-disable-next-line no-bitwise, no-mixed-operators
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

function getUserTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

function getDatetimeFromValue(value) {
  let isoString = value;
  if (typeof value !== 'string') {
    isoString = value.toISOString();
  }
  return DateTime.fromISO(isoString);
}

function getDisplayValue(luxonDatetime) {
  const tz = getUserTimezone();
  return luxonDatetime.setZone(tz).toLocaleString(DateTime.DATETIME_MED);
}

function parseISOString(dateString) {
  const datetime = getDatetimeFromValue(dateString);
  return getDisplayValue(datetime);
}

function unpackErrors(errorValue) {
  let error = '';
  if (errorValue.constructor === Array) {
    error = errorValue.join('. ');
  } else {
    error = errorValue;
  }
  return error;
}

export default {
  uuid4,
  getUserTimezone,
  parseISOString,
  unpackErrors,
};
